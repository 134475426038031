@import "variables";

.listaimages{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media(max-width:768px){
    width: 100%;
    flex-direction: column;
  }

}


.img{
  width: 300px;
  height: 300px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin:10px;
  @media(max-width:768px){
    width: 400px;
    height: 400px;
  }

}

//.img:hover{
//  width: 500px;
//  height: 600px;
//  cursor: pointer;
//}

.ustawieniegallery{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction:column;
  margin-bottom: 50px;
  margin-top: 50px;

}


.mainDivGallery{
  border-bottom: 5px solid  $colordecoration;

}