@import "variables";

.mainDivFooter{
  background-color: darkgray;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media(max-width:768px){
    width: 100%;
    height: 100%;
  }

}

.logo2{
  background-image: url("../images/logo żarówka wersja ostateczna.jpg");
  width: 25%;
  height: 60%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 100px;
  @media(max-width:768px){
    width: 0%;
  }

}

.footerAdres {
  display: flex;
  flex-direction: column;
  margin-left: 100px;
  p{
    color: white;
    size: 15px;
    font-family: $fontfamilyMain;
    line-height: 30px;
    @media(max-width:768px) {
      //font-size: 30px;
    }
  }
  h3{
    color: $colordecoration;
    font-size: 25px;
    font-family: $fontfamilyMain;
    margin-bottom:20px;
    @media(max-width:768px) {
      margin-bottom: 10px;
      //font-size: 40px;
    }
    @media(max-width:768px){
      margin-right: 50px;;
    }
  }
  @media(max-width:768px){
    margin-right: 80px;
  }

}

.footerKontakt{
  display: flex;
  flex-direction: column;
  p{
    color: white;
    size: 15px;
    font-family: $fontfamilyMain;
    line-height: 30px;
    @media(max-width:768px) {
      //font-size: 30px;
    }
  }
  h3{
    color: $colordecoration;
    font-size: 25px;
    font-family: $fontfamilyMain;
    margin-bottom:20px;
    @media(max-width:768px) {
      margin-bottom: 10px;
      //font-size: 40px;
    }
  }
}

.blackLine{
  background-color: black;
  color: white;
  font-family: $fontfamilyMain;
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: start;
  font-size: 10px;
  filter: brightness(50%);
}


.text{

}