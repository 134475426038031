@import "variables";

*{
  margin: 0;
  padding: 0;
}

.mainDivHeader{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 5px solid $colordecoration;

  @media(max-width:768px){
    width: 100%;
    height: 100%;
  }
}

.redline{
  width: 100%;
  height: 10px;
  background-color: $colordecoration;
}

.logo{
  background-image: url("../images/logo żarówka wersja ostateczna.jpg");
  width: 25%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 50px;
  @media(max-width:768px){
    width: 30%;
    //height: 80%;
    margin-left: 0;

  }
}

.navigation{
  display: flex;
  align-items: center;
 justify-content: center;
  margin-right: 50px;
  font-family: $fontfamilyMain;

  @media(max-width:768px){
    background-color: black;
    margin-right: 0;
  }
}

.ulnavigation{
  display: flex;
  flex-direction: row;
  list-style: none;

}

li a {
  text-decoration: none;
  font-size: 20px;
  margin-right: 30px;
  text-align: center;
  color:black;
  @media(max-width:768px){
    margin-right: 15px;
    //font-size: 40px;
    color: white;
    //border-right: 5px solid $colordecoration;
    height: 100%;
  }
}