.mainDivDefault{
  width: 100%;
  height: 100%;
  @media(max-width:768px){
    width: 100%;
    height: 100%;
  }
}

.mainImage{
  //background-image: url("../images/close-up-of-telephone-booth-257736.jpg");
  //background-image: url("../images/photo-1552862750-746b8f6f7f25.jpeg");
  background-image: url("../images/LED-Lightbulb.jpg");
  width: 100%;
  height: 600px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 15px;
  //filter: brightness(50%);
  display: flex;
  justify-content: center;
  align-items: center;
  @media(max-width:768px){
    width: 100%;
    height: 400px;
  }
}

#title{
  color: white;
  font-family: $fontfamilyTitle;
  font-weight: 700;
  font-size: 90px;
  line-height: 1.4em;
  margin-left: 20px;
  @media(max-width:768px){
    width: 100%;
    font-size: 50px;

  }
}

.foto1{
  background-image: url("../images/skrzynka.jpg");
  width: 350px;
  height: 300px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom:50px;
  //margin-top: 15px;
  //filter: brightness(50%);
  //display: flex;
  //justify-content: center;
  //align-items: center;
  @media(max-width:768px) {
    margin-top: 10px;
    margin-bottom: 30px;
  }
}

.foto2 {
  background-image: url("../images/uziemienie.jpg");
  width: 350px;
  height: 300px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 50px;
  @media(max-width:768px) {
    margin-top: 10px;
    margin-bottom: 30px;
  }
}

.foto3 {
  background-image: url("../images/gniazda.jpg");
  width: 350px;
  height: 300px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 50px;
  margin-bottom: 50px;
  @media(max-width:768px) {
    flex-direction: column;
    justify-content: center;
    margin-left: 0;
    margin-right: 0;
    margin-top: 10px;
    margin-bottom: 30px;
  }


}

.sets{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  //width: 100%;
  margin-top:50px;
  margin-right: 100px;
  margin-left: 100px;
  font-family: $fontfamilyMain;
  border-bottom: 5px solid $colordecoration;
  line-height: 30px;
  @media(max-width:768px){
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  h1{
    margin-bottom: 30px;
  }
}

.sets3{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  margin-top:50px;
  font-family: $fontfamilyMain;
  border-bottom: 5px solid $colordecoration;
  line-height: 30px;
  @media(max-width:768px){
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }
  h1{
    margin-bottom: 30px;
    margin-left: 50px;
  }
  li{
    margin-left: 50px;
  }

}

.mainSets{

}

#text{
  margin-left: 30px;
  @media(max-width:768px){
    margin-left: 10px;
  }
}