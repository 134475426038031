@import "variables";

.offer{
  margin-left: 200px;
  margin-top: 100px;
  font-family: $fontfamilyMain;
  h1{
    margin-bottom:20px;
  }
  li{
    margin-bottom: 10px;
    font-size: 25px;
  }
  @media(max-width:768px){
    margin-left: 50px;
  }
}


.offer li:last-of-type{
  margin-bottom: 100px;
}


.mainDivOffer{
  width: 100%;
  height: 100%;
  border-bottom: 5px solid $colordecoration;
  @media(max-width:768px){
    width: 100%;
    height: 100%;
  }
}