@import "variables";

.mainDivContact{
  width: 100%;
  height: 100%;
  border-bottom: 5px solid $colordecoration;
  @media(max-width:768px){
    width: 100%;
    height: 100%;
  }
}

.headerContact{
  width: 100%;
  height: 200px;
  color: white;
  font-family: 'Black Ops One', cursive;
  font-size:90px;
  background-color: $colordecoration;
  display: flex;
  align-items: center;
  justify-content: start;
  p{
    margin-left: 200px;
    @media(max-width:768px){
      margin-left: 50px;
      width: 100%;
      height: 100px;
      font-size: 70px;
    }
  }
}


.setKontakt {
  margin-left: 200px;
  margin-top: 100px;
  @media(max-width:768px){
    margin-left: 50px;
  }
}
.dwieLitery{
  color: $colordecoration;
  font-size: 50px;
  font-family: $fontfamilyMain;
}
.trzyLitery{
  color: black;
  font-size: 50px;
  font-family: $fontfamilyMain;
}

.logoKontakt {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  font-weight: bold;
  justify-content: center;
}

.name{
  color: black;
  font-size: 50px;
  font-family: $fontfamilyMain;
  margin-bottom: 30px;
  text-align: center;
}

.tel{
  color: black;
  font-size: 40px;
  font-family: $fontfamilyMain;
  margin-bottom: 15px;
  text-align: center;
}

.mail {
  color: black;
  font-size: 40px;
  font-family: $fontfamilyMain;
  margin-bottom: 15px;
  text-align: center;
  a{
    text-decoration: none;
    color: $colordecoration;
  }
}

.adresKontakt{
  color: black;
  font-size: 20px;
  font-family: $fontfamilyMain;
  text-align: center;

}

#nip{
  margin-bottom: 100px;
}

.setMaps{
  display: flex;
  flex-direction: row;
  @media(max-width:768px){
    flex-direction: column;
  }
}

.contact-map{
  margin-top: 50px;
  margin-left: 50px;
  @media(max-width:768px) {
    margin-left: 0px;
  }
}